/* Style Css Start Here */

/* Universal Css Start Here */
body {
	background-color: var(--body-color);
	font-family: var(--font-default);
	color: var(--default-text-color);
}
a {
	text-decoration: none;
	color: var(--extra-color-1);
}
button {
	font-family: var(--font-default);
}
figure {
	margin-bottom: 0;
}
.primary-btn {
	background: var(--primary-btn-color);
	padding: 12px 20px;
	color: var(--primary-btn-text-color);
	font-size: 14px;
	transition: 0.4s;
	border: 0;
}
.primary-btn i {
	margin-left: 11px;
}
.primary-btn:hover {
	background-color: var(--primary-btn-hover-back);
	color: var(--primary-btn-text-color);
}
.secondary-btn {
	background: var(--secondary-btn-color);
	padding: 12px 20px;
	color: var(--secondary-btn-text-color);
	font-size: 14px;
	transition: 0.4s;
	border: 1px solid var(--secondary-btn-text-color);
}
.secondary-btn i {
	margin-left: 11px;
}
.secondary-btn:hover {
	background-color: var(--secondary-btn-hover-back);
	color: var(--secondary-btn-text-hover-color);
}
.extra-btn {
	background: var(--extra-color-3);
	padding: 12px 20px;
	color: var(--default-text-color);
	font-size: 14px;
	transition: 0.4s;
}
/* Universal Css End Here */

/* Typography Start Here */
h1 {
	font-family: var(--font-bold);
	font-size: 58px;
	line-height: 68px;
}
h2 {
	font-family: var(--font-medium);
	font-size: 48px;
	line-height: 58px;
}
h3 {
	font-family: var(--font-light);
	font-size: 24px;
	line-height: 34px;
}
h4 {
	font-family: var(--font-default);
	font-size: 20px;
	line-height: 30px;
}
h5 {
	font-family: var(--font-default);
	font-size: 18px;
	line-height: 28px;
}
h6 {
	font-family: var(--font-default);
}
p {
	font-family: var(--font-default);
	font-size: 16px;
	line-height: 26px;
}
/* Typography End Here */

/* Go to Top Button Css Start Here */
.go_top.active button {
	transform: translateY(0);
}
.go_top button:hover {
	background: #007ac3;
	color: #fff;
	border-color: #007ac3;
}
.go_top button {
	width: 3.125rem;
	height: 3.125rem;
	z-index: 3;
	/* display: none; */
	cursor: pointer;
	position: fixed;
	bottom: 1.5rem;
	left: 0;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	background-color: #fff;
	color: var(--default-text-color);
	font-size: 1rem;
	text-decoration: none;
	border-radius: 1.5625rem;
	border: 1px solid var(--default-text-color);
	box-shadow: 0 0 12px 0 rgb(0 0 0 / 30%);
	right: 0;
	margin: 0px auto;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: translateY(200%);
	transition: 0.6s;
}
.go_top a:hover {
	background: var(--default-text-color);
	color: #fff;
}
/* Go To Top Button Css End Here */

/* InnerBanner Css Start Here */
.inner-banner {
	background: #ededed;
}
.inner-banner .img-box img {
	width: 90%;
	margin: 0px auto 0px 0px;
}
.inner-banner .img-box {
	text-align: end;
}
.inner-banner .content-wrapper .title {
	font-size: 38px;
	color: #353535;
	line-height: 48px;
	padding-bottom: 5px;
}
.inner-banner .content-wrapper p {
	font-size: 17px;
	line-height: 31px;
}
.inner-banner .content-wrapper .button-group {
	margin-top: 30px;
}
/* InnerBanner Css End Here */

/* Detailed Banner Start Here */
.detail_banner {
	background: var(--extra-color-1);
	padding: 40px 0;
}
.detail_banner .page-title {
	font-size: 36px;
	line-height: 46px;
	color: #fff;
}
/* Detailed Banner End Here */

/* BreadCrumbs Css Start Here */
.breadcrumbs {
	padding: 5px 0 18px;
}
.breadcrumbs ul.bread-crumbs {
	padding: 0;
	margin: 0;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
}
.breadcrumbs ul.bread-crumbs li a:after {
	content: "\f105";
	font-family: "FontAwesome";
	font-size: 11px;
	color: #757575;
	margin: 0 8px;
}
.breadcrumbs ul.bread-crumbs li a {
	color: var(--extra-color-1);
	font-size: 13px;
	font-family: var(--font-default);
}
.breadcrumbs ul.bread-crumbs li {
	font-size: 13px;
	font-family: var(--font-default);
	color: #757575;
}
/* BreadCrumbs Css End Here */

/* Secondary Header Css Start Here */
.bottom-header.fixed-header {
	position: fixed;
	top: 27px;
	width: 100%;
	z-index: 9;
}
.bottom-header {
	background: #000;
	transition: 0.6s;
}
.bottom-header ul.menus {
	padding: 0;
	margin: 0;
	list-style: none;
}
.bottom-header ul.menus li {
	display: inline-block;
	margin-right: 30px;
	position: relative;
}
.bottom-header ul.menus .dropdown-li {
	position: unset;
}
.bottom-header ul.menus .dropdown-li .dropdown-menu {
	overflow-x: hidden;
	border: 0;
	margin-top: 10px !important;
	padding: 20px 9rem 10px;
	border-radius: 0;
	border: 2px solid;
}
.bottom-header ul.menus li.active:before {
	content: "";
	background: #80bde1;
	width: 100%;
	height: 4px;
	position: absolute;
	bottom: 0;
	left: 0;
}
.bottom-header ul.menus .dropdown-li .dropdown-menu {
	overflow-x: hidden;
	border: 0;
	margin-top: 10px !important;
	padding: 20px 9rem 10px;
	border-radius: 0;
}
.bottom-header ul.menus .dropdown-li .dropdown-menu .title {
	padding-bottom: 10px;
}
.bottom-header ul.menus .dropdown-li .dropdown-menu .title h5 {
	font-family: var(--font-medium);
	color: #232323;
	font-size: 17px;
}
.bottom-header ul.menus .dropdown-li .dropdown-menu ul.inner-menus {
	padding: 0;
	margin: 0;
}
.bottom-header ul.menus .dropdown-li .dropdown-menu ul.inner-menus li {
	margin: 0 0 10px 0;
}
.bottom-header ul.menus .dropdown-li .dropdown-menu ul.inner-menus li a:hover {
	text-decoration: revert;
}
.bottom-header ul.menus li.active a {
	color: #80bde1;
}
.bottom-header ul.right-menus {
	text-align: end;
}
.bottom-header ul.right-menus li.menu-items:first-child:after {
	content: "";
	background: #dadada;
	width: 1px;
	height: 25px;
	position: absolute;
	left: -15px;
	top: 12px;
}
.bottom-header ul.right-menus li:last-child {
	margin-right: 0;
}
.bottom-header ul.menus li .menu-link {
	padding: 12px 0;
	display: block;
	color: #fff;
	font-family: var(--font-default);
}
.bottom-header ul.menus li button.menu-link {
	background: transparent;
	border: 0;
	padding: 0;
}
.bottom-header ul.menus li button.menu-link.show {
	color: #80bde1;
}
.bottom-header ul.menus li button.menu-link:after {
	display: none;
}
.bottom-header ul.menus li button.menu-link i {
	margin-left: 5px;
}
.bottom-header ul.menus li button.menu-link.show i:before {
	content: "\f106";
}
.bottom-header ul.menus li a.request-btn {
	background-color: #85bc20;
	color: #000;
	font-size: 1rem;
	padding: 12px 15px;
	text-align: center;
}
/* Secondary Header Css End Here */

/* Header Css Start Here */
.secondary-header {
	background: #353535;
	padding: 1px 0;
	position: fixed;
	width: 100%;
	top: 0px;
	z-index: 9;
}
.secondary-header .main-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.secondary-header .main-header .right-side ul {
	padding: 0;
	margin: 0;
	list-style: none;
}
.secondary-header .main-header .right-side ul li {
	display: inline-block;
}
.secondary-header .main-header .right-side ul li a {
	font-size: 12px;
	text-transform: uppercase;
	font-family: var(--font-medium);
	padding: 0 13px;
}
.secondary-header .left-side .dropdown {
	font-family: var(--font-medium);
	background: transparent;
	border: 0;
	padding: 0;
	color: var(--body-color);
	font-size: 12px;
}
.secondary-header .main-header button.btn {
	background: transparent;
	border: 0;
	padding: 0;
}
.secondary-header .main-header a {
	color: var(--body-color);
}
header {
	padding: 45px 0 15px;
}
header .topbar {
	display: flex;
	justify-content: space-between;
	padding-bottom: 20px;
	align-items: center;
}
header .logo-box img {
	max-width: 100px;
}
header .search-box {
	flex: 0 0 30%;
}
header .search-box form {
	position: relative;
}
header .search-box form .form-control {
	border-radius: 0;
	padding: 5px 0;
	box-shadow: none;
	border: 0;
	width: 50%;
	margin: 0px 0px 0px auto;
	transition: 0.6s;
	font-family: var(--font-light);
	font-size: 14px;
}
header .search-box form:before {
	width: 100%;
	height: 1px;
	position: absolute;
	bottom: -2px;
	right: 0;
}
header .search-box button {
	background: transparent;
	border: 0;
	padding: 0;
	color: #757575;
	font-size: 15px;
	margin-left: 30px;
}
header .search-box button.cross {
	display: none;
}
header .search-box form .form-group:before {
	content: "";
	background: var(--extra-color-1);
	width: 0%;
	height: 1px;
	position: absolute;
	bottom: -2px;
	right: 0;
	transition: 0.6s;
}
header .search-box form .button-group {
	position: absolute;
	top: 4px;
	right: 0;
	display: flex;
}
header .search-box.show .form-control {
	width: 100%;
	padding-right: 70px;
}
header .search-box.show form .form-group:before {
	width: 100%;
}
header .search-box.show form .button-group button.cross {
	display: block;
}
header .menus-bar {
	border-bottom: 6px solid var(--extra-color-1);
	padding: 10px 0;
}
header .menus-bar ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
header .menus-bar ul li {
	display: inline-block;
}
header .menus-bar ul li .menu-links {
	color: #474747;
	font-size: 20px;
	padding-right: 35px;
	transition: 0.6s;
}
header .menus-bar ul li .menu-links:hover {
	color: var(--extra-color-1);
}
/* Header Css End Here */

/* Demo Form Css Start Here */
.demo-form {
	padding: 60px 0;
	background: #ededed;
}
.demo-form .heading-wrapper {
	padding-bottom: 30px;
}
.demo-form .heading-wrapper h2 {
	font-family: var(--font-light);
	font-size: 30px;
	line-height: 42px;
	text-align: center;
}
.demo-form .form-group {
	margin-bottom: 15px;
}
.demo-form .form-group .form-control {
	border-radius: 0;
	border: 1px solid #bfbfbf;
	height: 60px;
	box-shadow: none;
}
.demo-form .products-check {
	padding-top: 10px;
}
.demo-form .products-check h5 {
	margin-bottom: 10px;
}
.demo-form .products-check .form-group label {
	margin-left: 10px;
	font-size: 15px;
}
.demo-form .privacy {
	display: flex;
	align-items: flex-start;
}
.demo-form .privacy input {
	margin-right: 10px;
}
.demo-form .privacy label {
	font-size: 14px;
}
.demo-form .privacy {
	margin-top: 30px !important;
}
.demo-form .button-group {
	margin-top: 25px;
}
.demo-form .button-group button {
	border: 0;
}
/* Demo Form Css End Here */

/* Banner Sec Css Start Here */
.banner_sec {
	background-image: url("../img/banner-img.jpg");
	height: 590px;
	display: flex;
	align-items: center;
	background-size: cover;
	background-position: center;
}
.banner_sec .content-box {
	background: #000000c7;
	max-width: 45%;
	padding: 45px 35px;
	color: var(--body-color);
}
.banner_sec .content-box p {
	padding: 10px 0;
}
.tagline {
	padding: 15px 0;
	text-align: end;
}
.tagline a {
	font-size: 20px;
}
.tagline a .bld {
	font-family: var(--font-medium);
}
.tagline a i {
	margin-left: 5px;
}
/* Banner Sec Css End Here */

/* Service Sec Css Start Here */
.service_sec {
	background: #ededed;
	padding: 30px 0;
}
.service_sec .heading_wrapper {
	padding-bottom: 20px;
	text-align: center;
}
.service_box {
	background: #fff;
	margin-bottom: 20px;
}
.service_box .img-box img {
	height: 190px;
	overflow: hidden;
	width: 100%;
	object-fit: cover;
	object-position: center center;
}
.service_box .content-box {
	padding: 20px 20px 40px;
}
.service_box .content-box .service-name {
	margin-bottom: 16px;
}
.service_box .content-box p {
	color: var(--default-text-color);
	min-height: auto;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.service_box .content-box .button-group {
	margin-top: 45px;
}
/* Service Sec Css End Here */

/* Solution Boxes Css Start Here */
.solution-box {
	position: relative;
	margin-top: 15px;
}
.solution-box .img-box img {
	height: 280px;
	width: 100%;
	object-position: center center;
	object-fit: cover;
}
.solution-box .content-box {
	background: #fff;
	width: 90%;
	margin: 0px 0px 0px auto;
	padding: 20px 20px 30px;
	margin-top: -70px;
	position: relative;
	box-shadow: 0px 0px 4px #ddd;
	color: #353535;
}
.solution-box .content-box .solution-name {
	font-family: var(--font-default);
	padding-bottom: 0px;
}
.solution-box .content-box .button-group {
	margin-top: 25px;
}
.solution-box .content-box p {
	color: var(--default-text-color);
	min-height: auto;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
/* Solution Boxes Css End Here */

/* Insight Css Start Here */
.insight_sec {
	padding: 40px 0;
}
.both-sides-bar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 35px;
}
.both-sides-bar .anchor {
	font-size: 16px;
	line-height: 16px;
	color: var(--extra-color-1);
}
.both-sides-bar .anchor i {
	margin-left: 10px;
}
.insight_sec .feature-insight-box {
	display: flex;
	align-items: center;
	border: 1px solid #ededed;
	transition: 0.5s;
	margin-bottom: 15px;
}
.insight_sec .feature-insight-box .img-box {
	flex: 0 0 50%;
}
.insight_sec .feature-insight-box .content-box {
	flex: 0 0 50%;
	padding: 0px 35px;
}
.insight_sec .feature-insight-box:hover {
	box-shadow: 0 4px 10px -3px rgb(0 0 0 / 30%);
}
.insight_sec .feature-insight-box .content-box .insight-name {
	font-size: 32px;
	line-height: 42px;
	font-family: var(--font-default);
}
.insight_sec .feature-insight-box .content-box .button-group {
	margin-top: 35px;
}
.insight-box .img-box img {
	height: 280px;
	object-fit: cover;
	object-position: center center;
}
.insight-box .content-box {
	background: #fff;
	width: 90%;
	margin: 0px 0px 0px auto;
	padding: 20px 20px 30px;
	margin-top: -70px;
	position: relative;
	box-shadow: 0px 0px 4px #ddd;
	color: #353535;
}
.insight-box .content-box .insight-name {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	font-family: var(--font-default);
}
.insight-box .content-box .button-group {
	margin-top: 25px;
}
/* Insight Css End Here */

/* About Css Start Here */
.about_sec {
	padding: 60px 0;
	background: #005b92;
	color: #fff;
	text-align: center;
}
.about_sec h3 {
	font-size: 36px;
	line-height: 56px;
}
.about_sec .button-group {
	margin-top: 40px;
}
.about_sec p {
	font-size: 18px;
	line-height: 28px;
}
.about_sec .button-group .extra-btn {
	padding: 15px 45px;
	color: #000;
	transition: 0.5s;
}
.about_sec .button-group .extra-btn:hover {
	background: #0000;
	border: 1px solid #fff;
	color: #fff;
}
/* About Css End Here */

/* News Sec Css Start Here */
.news_sec {
	padding: 70px 0;
}
.blog-box {
	border: 1px solid #ededed;
	margin-top: 15px;
}
.blog-box .blog-image {
	position: relative;
}
.blog-box .blog-image img {
	height: 160px;
	object-fit: cover;
	object-position: center center;
	width: 100%;
}
.blog-box .blog-image .tag {
	position: absolute;
	background: #ffffffd1;
	color: #4e4e4f;
	font-size: 11px;
	padding: 2px 14px 1px;
	border-radius: 50px;
	top: 10px;
	left: 10px;
	font-family: var(--font-medium);
	text-transform: uppercase;
	letter-spacing: 1.5px;
}
.blog-box .content-box {
	padding: 18px 18px 38px;
}
.blog-box .content-box .details {
	font-size: 10px;
	min-height: 52px;
}
.blog-box .content-box .details .category {
	text-transform: uppercase;
	color: #000;
	font-family: var(--font-medium);
	letter-spacing: 1px;
	margin-right: 10px;
}
.blog-box .content-box .details .date {
	color: #757575;
}
.blog-box .content-box .blog-description {
	color: #232323;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.blog-box .content-box .blog-name {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	font-family: var(--font-medium);
	color: var(--extra-color-1);
}
.blog-box .content-box .button-group {
	margin-top: 35px;
}
/* News Sec Css End Here */

/* Footer Css Start Here */
footer {
	background-color: #474747;
	color: #fff;
	position: relative;
	padding: 40px 0;
}
footer .green-line {
	background: #85bc20;
	width: 100%;
	height: 5px;
	margin-bottom: 25px;
}
footer .red-line {
	background: #e5202e;
	width: 100%;
	height: 5px;
	margin-bottom: 25px;
}
footer .menus {
	padding: 0;
	margin: 0;
	list-style: none;
}
footer .menus li {
	margin: 10px 0;
}
footer .menus li a {
	color: #fff;
	font-family: var(--font-light);
}
footer .social-icons .title {
	font-size: 16px;
}
footer .social-icons ul {
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 55%;
	margin-top: 20px;
}
footer .social-icons ul li a {
	background-color: #fff;
	color: #474747;
	font-size: 16px;
	border-radius: 50%;
	width: 35px;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
}
footer .footer-logo {
	padding-top: 20px;
}
footer .footer-logo img {
	max-width: 130px;
}
footer .right-content {
	text-align: end;
	height: 79%;
	display: flex;
	align-items: end;
}
.copyright_sec {
	background: #353535;
	padding: 20px 0;
}
.copyright_sec .sitesetting {
	padding: 0;
	margin: 0;
	list-style: none;
}
.copyright_sec .sitesetting li {
	display: inline-block;
	margin-right: 30px;
}
.copyright_sec p {
	margin: 15px 0 0 0;
	color: #fff;
	font-size: 14px;
	font-family: var(--font-light);
}
.copyright_sec .sitesetting li a {
	color: #fff;
}
/* Footer Css End Here */

/* Style Css End Here */
